<template>
  <div id="wrapper">
    <h1>Follow Along...</h1>
    <div id="three-photo-div">
      <div class="third">
        <img src="../assets/Footer_6262.jpg" alt="" />
        <button>
          <a href="https://facebook.com/laurynchayce" target="_blank">
            Facebook</a
          >
        </button>
      </div>
      <div class="third">
        <img src="../assets/Footer_4304.jpg" alt="" />
        <button>
          <a href="https://instagram.com/laurynchayce" target="_blank">
            Instagram</a
          >
        </button>
      </div>
      <div class="third">
        <img src="../assets/Footer_3624.jpg" alt="" /><button>
          <a href="https://liketoknow.it/laurynchayce" target="_blank"
            >LikeToKnow.it</a
          >
        </button>
      </div>
      <div class="third">
        <img src="../assets/Footer_9226.jpg" alt="" /><button>
          <a href="https://pinterest.com/laurynchayce" target="_blank">
            Pinterest</a
          >
        </button>
      </div>
    </div>
    <div id="subscribe-bar">
      <!-- <h1>Subscribe To My Updates</h1> -->
      <h1>Wanna get my updates?</h1>
      <!-- Begin Mailchimp Signup Form -->

      <div id="mc_embed_signup">
        <form
          action="https://laurynchayce.us1.list-manage.com/subscribe/post?u=7ce39bf009f4dea0b782470d4&amp;id=d06446513e"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              value=""
              name="EMAIL"
              class="email"
              id="mce-EMAIL"
              placeholder="email address"
              required
            />
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div aria-hidden="true" id="hidden">
              <input
                type="text"
                name="b_7ce39bf009f4dea0b782470d4_d06446513e"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </form>
      </div>

      <!--End mc_embed_signup-->
      <div id="info-tab">
        <div id="copyright">
          <h5>Copyright, 2021, Lauryn Chayce, LLC</h5>
        </div>
        <div id="signature">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-10 -10 510.22 190.79"
          >
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  d="M100.22,70.74c21.85-19.6,38.83-36.92,51-50,13.37-14.38,16.11-18.43,15-20-2.85-4-30.48,9-53,25-3,2.17-9.62,7.42-34,30-13.5,12.5-42.5,43.5-56,59-7.45,8.55-20.81,27-23,38a11.27,11.27,0,0,0,4,11c7.74,5.49,18-1,26-8,7.31-6.4,11.33-12.33,16-18,14-17,37-48,43-47,1.19.2,3,3,3,3a3.17,3.17,0,0,0-4.5-2.5c-3,1-5.43,5.31-5.5,5.5-.37,1-1.13,3.17,0,5,1.24,2,4,2.44,6,2a6.66,6.66,0,0,0,5-6c0-1.71-2.22-6.06-2-6,2.09.57,10.11,3,16,1a35,35,0,0,0,10-6c27.37-24.35,84.23-66.06,81-70-5-6.09-68.53,49.44-84,71-.78,1.08-3.44,3.35-4,7-.75,4.92,2.35,9.64,5,12,5.52,4.92,15.19,5,24,0"
                />
                <path
                  d="M250.22,80.74c10.17-9.21,24-20,40-40,11.56-14.46,21-28,19-32-1-1.91-6.1-4.32-23,4-63,31-124,96-142,127a14.48,14.48,0,0,0-1,12c1.35,4,4.42,6.6,7,7,13,2,40.22-20,49-28,12.94-11.79,22-23,28-29,2.53-2.54,3.14-4.27,5.08-6.17a11.13,11.13,0,0,1,1.75-1.39,8.82,8.82,0,0,1,4.17-1.44c3,0,3,2,3,2-.26-.85-8.73-3.94-10,4-.21,1.28,1.11,2.5,2,3,2.37,1.31,5.51-.57,7-2,.59-.58,2.26-2.18,2-4s-3-2-3-2,6.57,2.35,12,1c4-1,19-6,68-39,15.41-10.38,47.36-36.09,46-38-.79-1.11-7.86,3.26-40,26,0,0-31.94,23.23-39,44a3.85,3.85,0,0,0,0,3c1.59,2.75,23.76-8.71,24-11,.12-1.14-3.21-1.83-4-1-.53.55-.09,1.75,0,2,0,0,.94,2.58,5,4,12.87,4.49,109-6,109-6,18.61-2,46.4-1.78,82,11"
                />
              </g>
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
#wrapper {
  width: 100%;
}

input {
  font-family: Brandon Grotesque, Helvetica, sans-serif;
  margin: 10px;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid #737373;
}

form {
  display: flex;
  flex-direction: row;
}

#hidden {
  display: none;
}

img {
  object-fit: cover;
  aspect-ratio: 2, 3;
}

#mc_embed_signup_scroll {
  width: 50%;
  display: flex;
  flex-direction: row;
  margin: 0 30% 0 30%;
  justify-self: center;
  align-items: center;
}

#mc_embed_signup_scroll input {
  width: 98%;
}

#mc_embed_signup_scroll button {
  width: 60px;
}

#mc_embed_signup {
  padding: 0;
}

#mc_embed_signup_scroll label {
  margin: 5px 10px;
}

#mc-embedded-subscribe {
  width: 100px;
}

#info-tab {
  width: 90%;
  margin: 0 5% 0 5%;
  display: flex;
  flex-direction: row;
}

#copyright {
  width: 50%;
}

#copyright h5 {
  width: 100%;
  text-align: left;
}

#signature {
  width: 50%;
  display: flex;
  justify-content: right;
  justify-items: right;
}

#signature svg {
  max-height: 30px;
  stroke: #737373;
  stroke-width: 5px;
  fill: none;
  align-self: center;
  justify-self: right;
  padding-left: 90%;
}

#subscribe-bar {
  min-width: 100%;
  background-color: #fcf5f5;
}

@media only screen and (max-width: 479px) {
  #three-photo-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 100;
    margin-right: 0;
    margin: 0;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h1 {
    padding-top: 20px;
  }

  #mc_embed_signup_scroll {
    width: 90%;
    display: flex;
    justify-self: center;
    align-items: center;
    margin: 0 5% 0 5%;
    padding: 0;
  }

  #mc-embedded-signup-form {
    padding: 0;
  }

  #mc-embedded-subscribe {
    width: 100px;
  }

  #info-tab {
    width: 90%;
    display: flex;
    flex-direction: row;
  }

  #copyright {
    width: 50%;
  }

  #copyright h5 {
    font-size: 10px;
    width: 100%;
    text-align: left;
  }

  #signature {
    width: 50%;
    display: flex;
    justify-content: right;
    justify-items: right;
  }

  #signature svg {
    width: 30%;
    height: 100%;
    stroke: #737373;
    stroke-width: 5px;
    fill: none;
    align-self: center;
    justify-self: right;
    padding-left: 70%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  #three-photo-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 5% 0 5%;
    /* justify-content: center;
    justify-items: center; */
  }

  .third {
    flex: 1;
    height: 400px;
    /* width: calc(100% / 4); */
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #subscribe-bar {
    padding-top: 50px;
  }

  h1 {
    font-size: 60px;
  }
  #mc_embed_signup_scroll {
    width: 60%;

    display: flex;
    justify-self: center;
    align-items: center;
    margin: 0 20% 0 20%;
    padding: 0;
  }

  #mc-embedded-signup-form {
    padding: 0;
  }

  #info-tab {
    width: 90%;
    display: flex;
    flex-direction: row;
  }

  #copyright {
    width: 50%;
  }

  #copyright h5 {
    font-size: 10px;
    width: 100%;
    text-align: left;
  }

  #signature {
    width: 50%;
    display: flex;
    justify-content: right;
    justify-items: right;
  }

  #signature svg {
    width: 30%;
    height: 100%;
    stroke: #737373;
    stroke-width: 5px;
    fill: none;
    align-self: center;
    justify-self: right;
    padding-left: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 12px;
    color: #737373;
    border: none;
    width: 90%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #subscribe-bar h1 {
    padding-top: 40px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #subscribe-bar h1 {
    padding-top: 40px;
    font-size: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    color: #737373;
    border: none;
    min-width: 70%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #subscribe-bar {
  }

  #subscribe-bar h1 {
    /* height: 250px; */
    padding-top: 40px;
    font-size: 40px;
  }
  form {
    display: flex;
    flex-direction: row;
  }

  #hidden {
    display: none;
  }

  img {
    object-fit: cover;
    aspect-ratio: 2, 3;
  }

  #mc_embed_signup_scroll {
    width: 39%;
    display: flex;
    flex-direction: row;
    margin: 0 30% 0 30%;
    justify-self: center;
    align-items: center;
  }

  #mc_embed_signup_scroll label {
    margin: 5px 10px;
  }

  #mc-embedded-subscribe {
    width: 100px;
  }

  #info-tab {
    width: 90%;
    margin: 0 5% 0 5%;
    display: flex;
    flex-direction: row;
  }

  #copyright {
    width: 50%;
  }

  #copyright h5 {
    width: 100%;
    text-align: left;
  }

  #signature {
    width: 50%;
    display: flex;
    justify-content: right;
    justify-items: right;
  }

  #signature svg {
    max-height: 30px;
    stroke: #737373;
    stroke-width: 5px;
    fill: none;
    align-self: center;
    justify-self: right;
    padding-left: 90%;
  }

  #subscribe-bar {
    min-width: 100%;
    background-color: #fcf5f5;
  }
}

@media only screen and (min-width: 1200px) {
  h1 {
    font-size: 100px;
    margin-top: 80px;
  }
  #three-photo-div {
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: Brandon Grotesque, helvetica, sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    min-width: 70%;
    padding: 20px 20px 20px 20px;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #subscribe-bar {
    min-height: 300px;
    min-width: 100%;
    background-color: #fcf5f5;
  }

  #subscribe-bar h1 {
    padding-top: 60px;
  }
}
</style>