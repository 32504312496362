<template>
  <div
    class="backdrop"
    @click="searchBarOpened = false"
    v-if="searchBarOpened"
  ></div>

  <div id="container">
    <div class="circular-icon" id="search-button">
      <transition name="grow">
        <div id="search-container" v-if="searchBarOpened">
          <input
            type="text"
            placeholder="Type here..."
            v-model="searchTerm"
            @keydown.enter="searchBlog()"
          />
        </div>
      </transition>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18.005"
        viewBox="0 0 18 18.005"
        id="search-icon"
        @click="searchBarOpened = !searchBarOpened"
        v-if="!searchBarOpened"
      >
        <g
          id="Icon_ionic-ios-search"
          data-name="Icon ionic-ios-search"
          transform="translate(0)"
        >
          <path
            id="Icon_ionic-ios-search-2"
            data-name="Icon ionic-ios-search"
            d="M3.211,19.7l5.006-5.053a7.134,7.134,0,1,1,1.083,1.1l-4.973,5.02a.77.77,0,0,1-1.088.028A.775.775,0,0,1,3.211,19.7Zm10.613-3.9a5.633,5.633,0,1,0-3.984-1.65A5.6,5.6,0,0,0,13.824,15.8Z"
            transform="translate(-3 -2.995)"
          />
        </g>
      </svg>
    </div>
    <a href="https://facebook.com/laurynchayce" target="_blank">
      <div class="circular-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.854"
          height="24"
          viewBox="0 0 12.854 24"
        >
          <g
            id="Icon_awesome-facebook-f"
            data-name="Icon awesome-facebook-f"
            transform="translate(0)"
          >
            <path
              id="Icon_awesome-facebook-f-2"
              data-name="Icon awesome-facebook-f"
              d="M13.085,13.5l.667-4.343H9.584V6.338a2.172,2.172,0,0,1,2.449-2.347h1.895V.293A23.105,23.105,0,0,0,10.564,0C7.132,0,4.888,2.08,4.888,5.846v3.31H1.073V13.5H4.888V24h4.7V13.5Z"
              transform="translate(-1.073)"
            />
          </g>
        </svg>
      </div>
    </a>
    <a href="https://instagram.com/laurynchayce" target="_blank">
      <div class="circular-icon">
        <svg
          id="Icon_ionic-logo-instagram"
          data-name="Icon ionic-logo-instagram"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            id="Path_1"
            data-name="Path 1"
            d="M15.75,4.5A3.761,3.761,0,0,1,19.5,8.25v7.5a3.761,3.761,0,0,1-3.75,3.75H8.25A3.761,3.761,0,0,1,4.5,15.75V8.25A3.761,3.761,0,0,1,8.25,4.5h7.5m0-1.5H8.25A5.265,5.265,0,0,0,3,8.25v7.5A5.265,5.265,0,0,0,8.25,21h7.5A5.265,5.265,0,0,0,21,15.75V8.25A5.265,5.265,0,0,0,15.75,3Z"
            transform="translate(-3 -3)"
          />
          <path
            id="Path_2"
            data-name="Path 2"
            d="M16.875,8.25A1.125,1.125,0,1,1,18,7.125,1.122,1.122,0,0,1,16.875,8.25Z"
            transform="translate(-3 -3)"
          />
          <path
            id="Path_3"
            data-name="Path 3"
            d="M12,9a3,3,0,1,1-3,3,3,3,0,0,1,3-3m0-1.5A4.5,4.5,0,1,0,16.5,12,4.5,4.5,0,0,0,12,7.5Z"
            transform="translate(-3 -3)"
          />
        </svg>
      </div>
    </a>
    <a href="https://pinterest.com/laurynchayce" target="_blank">
      <div class="circular-icon">
        <svg
          id="Icon_metro-pinterest"
          data-name="Icon metro-pinterest"
          xmlns="http://www.w3.org/2000/svg"
          width="15.999"
          height="20.566"
          viewBox="0 0 15.999 20.566"
        >
          <path
            id="Icon_metro-pinterest-2"
            data-name="Icon metro-pinterest"
            d="M10.575,14.629c-.541,2.832-1.2,5.547-3.155,6.965-.6-4.28.885-7.493,1.577-10.9C7.818,8.7,9.139,4.713,11.624,5.7c3.059,1.209-2.649,7.375,1.183,8.146,4,.8,5.634-6.941,3.153-9.459C12.376.745,5.527,4.3,6.37,9.506c.2,1.273,1.52,1.659.525,3.416C4.6,12.414,3.916,10.6,4,8.192c.142-3.949,3.547-6.712,6.963-7.1,4.32-.484,8.374,1.587,8.935,5.649.63,4.587-1.95,9.556-6.57,9.2-1.252-.1-1.777-.719-2.758-1.315Z"
            transform="translate(-3.997 -1.028)"
          />
        </svg>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchBarOpened: false,
      searchTerm: null,
    };
  },
  methods: {
    searchBlog() {
      this.searchBarOpened = false;
      this.$router.push("/search/" + this.searchTerm);
      console.log(this.searchTerm);
      this.searchTerm = null;
    },
  },
};
</script>

<style scoped>
#container {
  position: fixed;
  bottom: 2%;
  right: 1%;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100;
}
.circular-icon {
  height: 40px;
  width: 40px;
  background-color: #fcf5f5;

  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  z-index: 6;
}

.circular-icon:hover {
  background-color: #ebebeb;
}

.circular-icon svg {
  fill: #5b5b5f;
  align-self: center;
  justify-self: center;
  position: relative;
}
.circular-icon:hover svg {
  fill: white;
}

#search-container {
  position: relative;
  min-width: 200px;
  background-color: #fcf5f5;
  right: 80px;
  align-self: right;
  flex: 1;
  border-radius: 20px;
  z-index: 4;
}

#search-container input {
  height: 40px;
  background-color: #fcf5f5;
  border: 0;
  width: calc(90%);
  border-radius: 20px;
  z-index: 4;
  margin-left: 20px;
  font-family: Brandon Grotesque, helvetica, sans-serif;
  font-weight: 600;
}

#search-container input:focus {
  outline: none;
}

#search-icon {
  z-index: 11;
}

.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.grow-enter-active,
.grow-leave-active {
  transition-property: width;
  transition-duration: 1s;
}
.grow-enter-from,
.grow-leave-to {
  width: 10px;
}

.grow-enter-to,
.grow-leave-from {
  width: 100px;
}

@media (min-width: 767px) {
  #container {
    position: fixed;
    top: 20%;
    right: 1%;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
}
</style>