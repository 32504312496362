<template>
  <div id="wrapper">
    <loading-icon v-if="isLoading"></loading-icon>
    <div id="post-container" v-if="!isLoading">
      <blog-post-preview
        v-for="post in posts"
        :key="post.id"
        :title="post.title.rendered"
        :excerpt="post.excerpt.rendered"
        :featuredImage="post.jetpack_featured_media_url"
        :id="post.slug"
        :date="post.date"
      ></blog-post-preview>
    </div>
  </div>
</template>

<script>
import WPAPI from "wpapi";
import BlogPostPreview from "../components/BlogPostPreview.vue";
import LoadingIcon from "../components/LoadingIcon.vue";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});
export default {
  data() {
    return {
      searchTerm: this.$route.params.searchterm,
      posts: null,
      isLoading: true,
    };
  },
  methods: {
    searchBlog() {
      wp.posts()
        .search(this.$route.params.searchterm)
        .embed()
        .get()
        .then((res) => {
          this.loadPosts(res);
        });
    },
    loadPosts(posts) {
      this.posts = posts;
      this.isLoading = false;
    },
    checkForReload() {
      if (this.searchTerm !== this.$route.params.searchterm) {
        this.posts = null;
        this.searchTerm = this.$route.params.searchterm;
        this.searchBlog;
      }
    },
  },

  components: { BlogPostPreview, LoadingIcon },
  created() {
    this.searchBlog();
  },
  beforeUpdate() {
    this.searchBlog();
  },
};
</script>

<style scoped>
#post-container {
  display: flex;
  flex-direction: column;
}
</style>