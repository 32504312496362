<template>
  <div id="wrapper">
    <h1 v-if="loaded">{{ cleanTitle() }}</h1>
    <div v-if="loaded" id="body" v-html="content"></div>
    <div id="temp"></div>
  </div>
</template>

<script>
import WPAPI from "wpapi";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});
export default {
  data() {
    return {
      loaded: true,
      postId: null,
      post: null,
      code: null,
      content: null,
    };
  },
  methods: {
    getPost() {
      this.postId = this.$route.params.id;
      wp.posts()
        .slug(this.postId)
        .embed()
        // .media()
        .get()
        .then((res) => {
          console.log(res);
          // console.log(res.content.rendered);
          // this.loadPost(res);
          this.separateCodeAndPost(res);
        });
    },
    separateCodeAndPost(res) {
      // separate code from content

      let content = res[0].content.rendered.split("<pre");
      // make code whole again
      if (content[1]) {
        let code = "<pre" + content[1].toString();

        // helper functions to clean unicode characters
        code = code.replaceAll("&amp;", "&");
        code = code.replaceAll("&lt;", "<");
        code = code.replaceAll("&#91;", "[");
        code = code.replaceAll("&gt;", ">");

        // creating the class attributes as variables

        let divClass = code.split("<div ");
        let firstClassindex = divClass[1].indexOf("class");
        let finalClassindex = divClass[1].indexOf("widget");
        divClass = divClass[1].slice(firstClassindex + 7, finalClassindex + 6);

        // creating the dynamic id as variables

        let divId = code.split("<div");
        divId = divId[1];
        let firstIdIndex = divId.indexOf("data-widget-id");
        let finalIdIndex = divId.indexOf(`">`);
        divId = divId.slice(firstIdIndex, finalIdIndex);

        // cleaning `="`
        divId = divId.split('="');

        // create element and set attributes
        let div = document.createElement("div");
        div.setAttribute("class", divClass);
        div.setAttribute(divId[0], divId[1]);

        //create script content and clean
        let script = code.split("<script");
        script = script[1].split("/script>");
        script = script[0].split(">");
        script = script[1];
        script = script.substring(0, script.length - 1);

        //create script tag

        let htmlScript = document.createElement("script");
        htmlScript.text = script;
        htmlScript.type = "text/javascript";

        //eval and append script tag to div

        eval(htmlScript);
        div.append(htmlScript);

        let temp = document.getElementById("temp");

        temp.append(div);
      }
      this.post = content[0];
      let post = content[0];
      this.loadPost(post, res);
    },
    loadPost(post, res) {
      this.post = res[0];

      this.content = post;
      this.loaded = true;
    },
    cleanTitle() {
      let title = this.post.title.rendered.replace(/&#8217;/, "'");
      return title;
    },
  },
  created() {
    (this.loaded = false), this.getPost();
  },
};
</script>

<style scoped>
h1 {
  font-family: "brandon-grotesque", sans-serif;

  text-transform: uppercase;
  font-size: 30px;
}

#body {
  width: 90%;
  margin: 0 5% 0 5%;
}

#body:deep(.wp-block-gallery) {
  max-width: 100%;
}

#body:deep(.wp-block-gallery > ul) {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: masonry;
}

#body:deep(.wp-block-gallery > ul > li) {
  list-style: none;
}

#body:deep(p),
#body:deep(li) {
  font-family: "brandon-grotesque", sans-serif;

  font-weight: 600;
}

#body:deep(ul) {
  display: flex;
  flex-direction: column;
}
#body:deep(li) {
  padding: 10px;
  text-transform: none;
}
#body:deep(a) {
  color: #737373;
  background-color: #fcf5f5;
}
#body:deep(img) {
  width: 100%;
  object-fit: contain;
  max-height: 200px;
}

#temp {
  /* width: 100%; */
  justify-content: center;
  justify-items: center;
  padding-left: 15%;
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #body:deep(img) {
    width: 100%;
    object-fit: contain;
    max-height: 350px;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  #body:deep(img) {
    width: 100%;
    object-fit: contain;
    max-height: 450px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1160px) {
  #temp {
    justify-content: center;
    justify-items: center;
    padding-left: 10%;
    padding-right: 10%;
  }
  #body:deep(img) {
    width: 100%;
    object-fit: contain;
    max-height: 500px;
  }
}

@media only screen and (min-width: 1024px) {
  #body:deep(img) {
    width: 100%;
    object-fit: contain;
    max-height: 500px;
  }
}
</style>