<template>
  <social-sharing-links></social-sharing-links>

  <div class="home">
    <div id="hero">
      <div id="instagram-feed-wrapper">
        <div
          class="elfsight-app-ea858059-b179-4b3c-88f8-ba0a39a368d9"
          id="instagram-feed"
        ></div>
      </div>
      <div id="upper-navigation" @mouseleave="shopMenuOpened = false">
        <ul>
          <li>
            <router-link to="/">
              <p>Home</p>
            </router-link>
          </li>
          <li
            class="vertical-align"
            id="dropdown-parent"
            @mouseenter="shopMenuOpened = true"
          >
            <div class="vertical-flex">
              <p>Shop</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.84"
                height="5.626"
                viewBox="0 0 9.84 5.626"
              >
                <path
                  id="Icon_ionic-ios-arrow-down"
                  data-name="Icon ionic-ios-arrow-down"
                  d="M7.5,8.616l3.721-3.724a.7.7,0,0,1,.993,0,.709.709,0,0,1,0,1L8,10.107a.7.7,0,0,1-.97.021L2.783,5.892a.7.7,0,0,1,.993-1Z"
                  transform="translate(-2.578 -4.686)"
                  fill="#5b5b5f"
                />
              </svg>
            </div>
            <div class="vertical-flex">
              <transition name="fade">
                <div
                  id="dropdown-menu"
                  v-if="shopMenuOpened"
                  @mouseenter="shopMenuOpened = true"
                  @mouseleave="shopMenuOpened = false"
                >
                  <ul>
                    <li>
                      <router-link to="/todayssales"
                        ><p>Today's Sales</p></router-link
                      >
                    </li>
                    <li>
                      <router-link to="/discounts"
                        ><p>Discount Codes</p></router-link
                      >
                    </li>
                    <li>
                      <a
                        href="https://liketoknow.it/laurynchayce"
                        target="_blank"
                        ><p>Shop My Insta</p></a
                      >
                    </li>
                    <li>
                      <a
                        href="https://amazon.com/shop/influencer-25f538a9"
                        target="_blank"
                        ><p>Amazon</p></a
                      >
                    </li>
                  </ul>
                </div>
              </transition>
            </div>
          </li>
          <li>
            <router-link to="/mystory"><p>Story</p></router-link>
          </li>
          <li>
            <router-link to="/contact"><p>Contact</p></router-link>
          </li>
        </ul>
      </div>
      <router-link to="/">
        <div id="header-logo">
          <h1>Lauryn Chayce</h1>
          <h3>Coleman</h3>
        </div>
      </router-link>
      <div id="strip-navigation">
        <ul>
          <li>
            <router-link to="/style"><p>Style</p></router-link>
          </li>
          <li>
            <router-link to="/beauty"><p>Beauty</p></router-link>
          </li>
          <li>
            <router-link to="/lifestyle"><p>Lifestyle</p></router-link>
          </li>
          <li>
            <router-link to="/recipes"><p>Recipes</p></router-link>
          </li>
          <li>
            <router-link to="/travel"><p>Travel</p></router-link>
          </li>
          <li>
            <router-link to="/decor"><p>Decor</p></router-link>
          </li>
        </ul>
      </div>
      <div>
        <three-photo-window v-if="isHome"></three-photo-window>
      </div>
    </div>
  </div>
</template>

<script>
import SocialSharingLinks from "./SocialSharingLinks.vue";
import ThreePhotoWindow from "./ThreePhotoWindow.vue";
export default {
  name: "Home",
  data() {
    return {
      shopMenuOpened: false,
    };
  },
  methods: {},
  computed: {
    isHome() {
      return this.$route.name === "Home";
    },
  },
  components: {
    SocialSharingLinks,
    ThreePhotoWindow,
  },
  beforeCreate() {
    let instagramFeed = document.createElement("script");
    instagramFeed.setAttribute(
      "src",
      "https://apps.elfsight.com/p/platform.js"
    );
    document.head.appendChild(instagramFeed);
  },
};
</script>

<style scoped>
div {
  margin: 0;
}
.home {
  width: 100vw;
  margin: 0;
}

#hero {
  display: flex;
  flex-direction: column;
  padding-bottom: 5%;
}

#upper-navigation {
  position: relative;
  background-color: #fcf5f5;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: none;
  width: 100%;
  height: 40px;
  display: flex;
  z-index: 100;
  align-content: center;
  align-items: center;
}

ul {
  list-style: none;
  display: flex;
  flex-direction: row;

  width: 90%;
  margin: 10px;
  padding: 5px;
  align-self: center;
  z-index: 10;
}

#dropdown-menu {
  max-width: 200px;
}

li {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 400;

  align-self: center;
  text-transform: uppercase;

  font-size: 16px;
  letter-spacing: 2px;
  flex: 1;
}
/* 
#instagram-feed-wrapper {
  min-height: 25vw;
  max-height: 25vw;
  overflow: hidden;
} */

#header-logo {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  height: 40vw;
  cursor: pointer;
}
#header-logo > h1 {
  margin: 0;
  font-weight: 400;
}
#header-logo h3 {
  text-transform: uppercase;
  font-size: calc(100vw / 35);
  font-weight: 400;
  letter-spacing: 10px;
  position: relative;
  top: -20%;
  left: 6%;
}

#strip-navigation {
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  font-weight: 400;

  border-left: none;
  background-color: #fcf5f5;
  display: flex;
  justify-items: center;
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
}

#strip-navigation ul {
  width: 100%;
}

#strip-navigation > ul li {
  padding: 15px;
}

li p {
  margin: 0;
  text-transform: uppercase;
  cursor: pointer;
  /* height: 100%; */
}

p:hover {
  color: #a6a6a6;
}

#dropdown-parent {
  position: relative;
}

.vertical-align {
  justify-items: center;
  justify-items: center;
}

.vertical-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin: 0;
}

.vertical-flex svg {
  width: 100%;
  position: absolute;
  bottom: -35%;
}

#dropdown-menu {
  align-self: center;
  justify-self: center;
  position: absolute;
  top: 30px;
  background-color: #fcf5f5;

  border-top: 0;
  display: flex;
  width: 200%;
  max-width: 200px;

  z-index: -1;
}

#dropdown-menu ul {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 479px) {
  #hero {
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
  }

  #upper-navigation {
    position: relative;
    border-left: none;
    width: 100%;
  }

  /* #instagram-feed-wrapper {
    min-height: 25vw;
    max-height: 25vw;
    overflow: hidden;
  } */

  #header-logo {
    margin-top: 20px;
    margin-bottom: 40px;
    height: 30vw;
  }
  #header-logo > h1 {
    font-size: calc(100vw * 0.25);
  }
  #header-logo h3 {
    font-size: calc(100vw / 35);
    top: -20%;
    left: 6%;
  }

  #dropdown-menu {
    top: 30px;
    width: 200%;
    z-index: -1;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  /* #instagram-feed-wrapper {
    min-height: 25vw;
    max-height: 25vw;
    overflow: hidden;
  } */

  #header-logo {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 30vw;
    cursor: pointer;
  }
  #header-logo > h1 {
    font-size: calc(100vw / 5);
  }
  #header-logo h3 {
    top: -20%;
    left: 4%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #upper-navigation {
    margin-top: 10px;
    width: 50%;
    z-index: 10;
  }

  #hero {
    padding-bottom: 20px;
  }

  /* #instagram-feed-wrapper {
    min-height: 200px;
    max-height: 200px;
    overflow: hidden;
  } */

  #header-logo {
    margin-top: 20px;
    /* margin-bottom: 40px; */
    display: flex;
    flex-direction: column;
    height: 250px;
    cursor: pointer;
  }
  #header-logo > h1 {
    font-size: 170px;
  }
  #header-logo h3 {
    font-size: 20px;
    top: -20%;
    left: 4%;
  }
  #strip-navigation ul {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  #upper-navigation {
    margin-top: 10px;
    width: 45%;
  }

  #hero {
    padding-bottom: 30px;
  }

  /* #instagram-feed-wrapper {
    min-height: 100px;
    max-height: 100px;
    width: 250%;
    overflow: hidden;
  } */

  #header-logo {
    height: 250px;
  }
  #header-logo > h1 {
    font-size: 170px;
  }
  #header-logo h3 {
    font-size: 20px;
    left: 3%;
  }

  #strip-navigation {
    overflow-x: hidden;
  }
  #strip-navigation ul {
    max-width: 80%;
  }
}

@media only screen and (min-width: 1200px) {
  #upper-navigation {
    width: 45%;
    max-height: 40px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  #strip-navigation {
    overflow-x: hidden;
  }

  #hero {
    padding-bottom: 40px;
  }

  /* #instagram-feed-wrapper {
    min-height: 150px;
    max-height: 150px;
    min-width: 250%;
  } */

  #header-logo {
    height: 220px;
  }
  #header-logo > h1 {
    font-size: 170px;
  }

  #header-logo h3 {
    font-size: 20px;
    top: -20%;
    left: 2%;
  }
  #strip-navigation ul {
    max-width: 80%;
  }
}
</style>