<template>
  <div>
    <h1>Contact Me!</h1>
  </div>
  <div id="form" :class="missingFields ? 'missing-fields' : ''">
    <div class="label-and-input">
      <h5>Name:</h5>
      <input type="text" placeholder="Your Name" v-model="formData.userName" />
    </div>
    <div class="label-and-input">
      <h5>Email Address:</h5>
      <input
        type="text"
        placeholder="you@domain.com"
        v-model="formData.userEmail"
      />
    </div>
    <div class="label-and-input">
      <h5>Message:</h5>
      <textarea type="textarea" v-model="formData.userMessage" />
    </div>
    <div class="row-flex">
      <h5>
        <input type="checkbox" v-model="formData.mailingCheck" />
        Check to be added to my mailing list!
      </h5>
    </div>
    <p class="error" v-if="missingFields">
      Oops! Look like you missed something!
    </p>
    <button @click="testEmail">Send!</button>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      missingFields: false,

      formData: {
        userName: null,
        userEmail: null,
        userMessage: null,
        mailingCheck: false,
      },
    };
  },
  methods: {
    testEmail() {
      console.log(this.formData);
      if (
        this.formData.userName === null ||
        this.formData.userName === undefined ||
        this.formData.userEmail === null ||
        this.formData.userEmail === undefined ||
        this.formData.userMessage === undefined ||
        this.formData.userMessage === null
      ) {
        this.missingFields = true;
      } else {
        emailjs
          .send("service_hwk8x2r", "template_2ucnxlk", {
            user_name: this.formData.userName,
            user_email: this.formData.userEmail,
            message: this.formData.userMessage,
          })
          .then((result) => {
            console.log("Success!", result);
          })
          .then(() => {
            this.formData = {
              userName: null,
              userEmail: null,
              userMessage: null,
              mailingCheck: false,
            };
            this.missingFields = false;
          })
          .then(() => {
            this.$router.push("/");
          }),
          (error) => {
            console.log("Failed...", error);
          };
      }
    },
    // testEmail() {
    //   transporter.verify(function(error, success) {
    //     if (error) {
    //       console.log(error)
    //     } else {
    //       console.log(success, "Success!")
    //     }
    //   })
    // }
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded();
    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha("login");
    //   console.log(token);
    //   // Do stuff with the received token.
    // },
  },
  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js");
    document.head.appendChild(externalScript);
  },
};
</script>

<style scoped>
#form {
  justify-content: left;
  justify-items: left;
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 15%;
  padding: 0 5% 5% 5%;
}

h1 {
  font-size: 100px;
}

.label-and-input {
  flex: 1;
  justify-self: left;
  justify-content: left;
  justify-items: left;
}

.row-flex {
  display: flex;
  flex-direction: row;
}

.row-flex h5 {
  width: 100%;
}

.missing-fields {
  border: 1px solid red;
}

.error {
  color: red;
}
.label-and-input h5 {
  text-align: left;
}

.label-and-input input {
  justify-self: left;
  width: 50%;
  margin-right: 30%;
}

input,
textarea {
  font-family: Brandon Grotesque, Helvetica, sans-serif;
  font-weight: 600;
  height: 1.75em;
  border-radius: 1em;
  padding-left: 10px;
}
textarea {
  height: 200px;
  border: 2px solid black;
  width: 70%;
  margin-right: 10%;
}

input:focus,
textarea:focus {
  border: 2px solid #fcf5f5;
  outline: none;
}

button {
  height: 40px;
  font-family: Brandon Grotesque, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  background-color: #fcf5f5;
  border-radius: 20px;
}

@media (min-width: 767px) {
  #form {
    background-color: #fcf5f5;
  }
}
</style>