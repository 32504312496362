<template>
  <div id="wrapper">
    <h1>Discount Codes</h1>
    <div v-if="loaded" id="body" v-html="post[0].content.rendered"></div>
  </div>
</template>

<script>
import WPAPI from "wpapi";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});
export default {
  data() {
    return {
      post: null,
      loaded: true,
    };
  },
  methods: {
    getPost() {
      this.loaded = false;
      wp.posts()
        .slug("discount-codes-post")
        .get()
        .then((res) => {
          this.loadPost(res);
        });
    },
    loadPost(post) {
      this.post = post;

      this.loaded = true;
    },
  },
  created() {
    this.getPost();
  },
};
</script>

<style scoped>
h1 {
  font-weight: 100;
  font-size: 70px;
}

#body:deep(p),
#body:deep(li) {
  font-family: Brandon Grotesque, helvetica, sans-serif;
  font-weight: 600;
}

#body:deep(a) {
  color: #737373;
  background-color: #fcf5f5;
}
@media (min-width: 767px) and (max-width: 1900px) {
  h1 {
    font-size: 100px;
  }
}
</style>