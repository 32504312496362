<template>
  <div id="wrapper">
    <loading-icon v-if="isLoading"></loading-icon>

    <div id="post-container" v-if="!isLoading">
      <blog-post-preview
        v-for="post in posts"
        :key="post.id"
        :title="post.title.rendered"
        :excerpt="post.excerpt.rendered"
        :featuredImage="post.jetpack_featured_media_url"
        :id="post.slug"
        :date="post.date"
      ></blog-post-preview>
    </div>
  </div>
</template>

<script>
import WPAPI from "wpapi";
import BlogPostPreview from "../components/BlogPostPreview.vue";
import LoadingIcon from "../components/LoadingIcon.vue";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});

export default {
  data() {
    return {
      isLoading: true,
      posts: [],
      categories: [],
    };
  },
  methods: {
    fetchPosts() {
      let index;

      wp.categories()
        .then((res) => {
          res.forEach((cat) => {
            if (cat.name === "Travel") {
              index = cat.id;
              return index;
            }
          });
        })
        .then(() => {
          wp.posts()
            .categories(index)
            .embed()
            .get()
            .then((res) => {
              this.loadPosts(res);
            });
        });

      // } catch (e) {
      // print error
      // console.log(e);
      // }
    },
    loadPosts(posts) {
      let array = [];
      posts.forEach((post) => {
        if (post.tags.includes(15)) {
          return;
        } else {
          array.push(post);
        }
      });
      this.posts = array;
      console.log(this.posts);
      this.isLoading = false;
    },
  },
  created() {
    // this.$nextTick(() => this.fetchPosts());
    this.fetchPosts();
  },
  components: { BlogPostPreview, LoadingIcon },
};
</script>

<style scoped>
#post-container {
  display: flex;
  flex-direction: column;
}
</style>