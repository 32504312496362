import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import BlogCategoriesBeauty from '../blogCategories/BlogCategoriesBeauty.vue'
import BlogCategoriesDecor from '../blogCategories/BlogCategoriesDecor.vue'
import BlogCategoriesLifestyle from '../blogCategories/BlogCategoriesLifestyle.vue'
import BlogCategoriesRecipes from '../blogCategories/BlogCategoriesRecipes.vue'
import BlogCategoriesStyle from '../blogCategories/BlogCategoriesStyle.vue'
import BlogCategoriesTravel from '../blogCategories/BlogCategoriesTravel.vue'
import BlogPostView from '../components/BlogPostView.vue'
import DiscountCodes from '../blogCategories/shopPages/DiscountCodes.vue'
import TodaysSales from '../blogCategories/shopPages/TodaysSales.vue'
import BlogSearchPage from '../blogCategories/BlogSearchPage.vue'
import ContactMe from '../staticPages/ContactMe.vue'
import MyStory from '../staticPages/MyStory.vue'
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/beauty",
    name: "Beauty",
    component: BlogCategoriesBeauty
  },
  {
    path: "/decor",
    name: "Decor",
    component: BlogCategoriesDecor
  },
  {
    path: "/lifestyle",
    name: "Lifestyle",
    component: BlogCategoriesLifestyle
  },
  {
    path: "/recipes",
    name: "Recipes",
    component: BlogCategoriesRecipes
  },
  {
    path: "/style",
    name: "Style",
    component: BlogCategoriesStyle
  },
  {
    path: "/travel",
    name: "Travel",
    component: BlogCategoriesTravel
  },
  {
    path: "/post/:id",
    name: "post",
    component: BlogPostView
  },
  {
    path: '/discounts',
    name: "discounts",
    component: DiscountCodes
  },
  {
    path: '/todayssales',
    name: "todayssales",
    component: TodaysSales
  },
  {
    path: '/search/:searchterm',
    name: "search",
    component: BlogSearchPage
  },
  {
    path: '/contact',
    name: "contact",
    component: ContactMe
  },
  {
    path: '/mystory',
    name: "mystory",
    component: MyStory
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound
  }

];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

