<template>
  <div class="row-flex">
    <img
      v-if="featuredImage"
      :src="featuredImage"
      :alt="title"
      @click="$router.push('/post/' + id)"
    />
    <div class="column-flex">
      <p>{{ convertDate() }}</p>
      <h1>{{ cleanTitle() }}</h1>
      <p v-html="excerpt"></p>
      <button @click="$router.push('/post/' + id)">Read the post</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cleanedTitle: this.cleanTitle(),
      newDate: this.$props.date,
    };
  },
  methods: {
    convertDate() {
      let a = this.newDate.split("T");
      let b = a[0];
      let yearObject = b.split("-");

      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let postMonth = months[parseInt(yearObject[1]) - 1];
      return postMonth + " " + yearObject[2] + ", " + yearObject[0];
    },
    cleanTitle() {
      let title = this.$props.title.replace(/&#8217;/, "'");
      return title;
    },
  },
  props: ["title", "excerpt", "featuredImage", "id", "date"],
  created() {
    this.convertDate();
  },
};
</script>

<style scoped>
.row-flex {
  display: flex;
  flex-direction: column;
  width: 90%;
  /* max-height: 425px; */
  background-color: #fafafa;
  padding: 20px;
  margin-bottom: 30px;
  justify-content: center;
  justify-items: center;
}

.column-flex {
  width: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}

p {
  flex: 1;
}

button {
  flex: 1;
  max-height: 30px;
  min-height: 30px;
  margin: 0 20% 0 20%;
  align-self: bottom;
  width: 60%;
  background-color: #fafafa;
  border: 2px solid #fcf5f5;
  font-family: Brandon Grotesque, helvetica, sans-serif;
  font-weight: 600;
  color: #737373;
  text-transform: uppercase;
  cursor: pointer;
}

button:hover {
  background-color: #fcf5f5;
}

img {
  width: 90%;
  margin: 0 5% 0 5%;
  /* max-height: 50%; */
  object-fit: cover;
}

h1 {
  font-family: Brandon Grotesque, helvetica, sans-serif;
  text-transform: uppercase;

  font-size: 25px;

  /* margin: 20px; */
  flex: 1;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .row-flex {
    flex-direction: row;
  }
  img {
    width: 35%;
  }
}

@media (min-width: 1024px) {
  .row-flex {
    flex-direction: row;
  }

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 20px;
  }

  button {
    font-size: 18px;
    max-height: 50px;
    height: 40px;
  }

  img {
    width: 30%;
  }
}
</style>