<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.24 69.09">
      <g id="Layer_1-2-2" data-name="Layer 1-2">
        <path
          class="path"
          :stroke-dashoffset="strokeOffset"
          d="M2.29,68.78A3.48,3.48,0,0,1,.14,65.25a4.46,4.46,0,0,1,0-.63,9,9,0,0,1,2.68-5A11.52,11.52,0,0,1,6.1,57.28a12.26,12.26,0,0,1,6.15-1.36h1.61l.49-1.48a54.21,54.21,0,0,0,2-9.27,83,83,0,0,0,.88-12.7A170,170,0,0,0,16,13.09q-1.23-11-2.2-11.58a1.05,1.05,0,0,1-.39-.68.58.58,0,0,1,.22-.46.76.76,0,0,1,.51-.25c.42,0,.88.39,1.37,1.17Q17.08,3.78,18,10.38A131.16,131.16,0,0,1,19.18,26.1v2q0,15.42-3.61,26.3c-.13.46-.24.82-.32,1.09a2.36,2.36,0,0,0-.12.54h0l.39.09c.26.07.59.14,1,.21q1.17.24,2.46.63l3.93,1.22q9.72,3.14,14.6,3.13h.29a8,8,0,0,0,2-.2,1.08,1.08,0,0,0,.78-.68c.13-.23.24-.34.34-.34s.2.13.2.39a1.6,1.6,0,0,1-1,1.17,4.66,4.66,0,0,1-.49.16,2.89,2.89,0,0,1-.83.07H37a29,29,0,0,1-3.36-.14c-.92-.09-2.2-.33-3.86-.68-1.14-.21-2.26-.47-3.37-.78s-2.67-.78-4.49-1.36a63.81,63.81,0,0,0-6.16-1.82l-1-.25-.25.64-.43.91-.64,1.29a22.4,22.4,0,0,1-5.2,7.1C6.47,68.24,4.91,69,3.58,69A3.73,3.73,0,0,1,2.29,68.78Z"
        />
        <path
          class="path"
          :stroke-dashoffset="strokeOffset"
          d="M4.64,67.85a10.23,10.23,0,0,0,3.27-2.29,21.06,21.06,0,0,0,3.17-4c.33-.53.72-1.23,1.18-2.12s.78-1.59,1-2.08l.25-.58-.69-.1a5.08,5.08,0,0,0-.78,0h-.93a16.9,16.9,0,0,0-2,.29,11.7,11.7,0,0,0-6,3.32A7.54,7.54,0,0,0,.76,65.22a3,3,0,0,0,.1.83,2.36,2.36,0,0,0,2.39,2.06A5.1,5.1,0,0,0,4.64,67.85Z"
        />
      </g>
    </svg>
    <h5>Loading...</h5>
  </div>
</template>


<script>
export default {
  data() {
    return {
      strokeOffset: 0,
    };
  },
  methods: {},
  created() {
    for (let x = 0; x < 10; x++) {
      setInterval(() => {
        this.strokeOffset = this.strokeOffset + 0.5;
      }, 25);
    }
  },
};
</script>

<style scoped>
div {
  width: 25%;

  margin: 0 37.5% 10% 37.5%;
}
svg {
  height: 200px;
}

.path {
  stroke: #737373;
  fill-opacity: 0;
  stroke-width: 0.5px;

  stroke-dasharray: 240;
}
</style>