<template>
  <div><h1>My Story</h1></div>
  <div>
    <p>
      Welcome to my blog! I can't wait to share everything about life, style,
      beauty, home, and especially, getting all of the good deals and sales! I
      have always been the type of person to search for the best deal as
      possible, but not sacrificing quality. Some of my favorite things to do
      are shop (of course), look for new ways to decorate my home, cook (I have
      always loved to cook!), and spend time with my family! If I could just
      stay snuggled up with my hubby and fur babies while drinking coffee or
      wine while watching a movie, I'm a happy girl!
    </p>

    <p class="has-text-align-center">
      I started to get into blogging in 2018, but I didn't really go full force
      until 2020/2021! I grew up with my parents being entrepreneurs, so
      naturally, I followed that path! I started working for my parents when I
      was around 10 years old at their wedding venue and I have been a part of
      the wedding business ever since. From decor, to catering, to flowers, to
      planning, to DJing, to photography, pretty much every aspect! I decided I
      wanted to pursue blogging because I absolutely love sharing my life with
      people and sharing all of my finds and sales. So here we are!
    </p>

    <p class="has-text-align-center">
      A little about my personal life. My husband, Cole, and I got married on
      September 8, 2019 and we have been together since 2014. We live in St.
      Louis, born and raised. We have absolutely adored being married, we still
      can't get enough of each other. We both work together from home and are
      always together, so we are absolutely best friends! We have a bunny named
      Stella and a brand new puppy named Tessa. They are our babies! We
      absolutely love to travel, we are pretty much gone as much as possible,
      which we are very fortunate to be able to do because of our jobs! Other
      than that, we are major homebodies and love to binge watch series and
      movies. We have owned our DJ company since 2017 and have built it from the
      ground up to be able to support us both full-time. Cole has also started
      his journey in software development, which we are so excited about! We
      can't wait to make you guys a part of our lives and take you along these
      journeys with us!
    </p>

    <p class="has-text-align-center"></p>

    <p class="has-text-align-center">Fun Facts!</p>

    <ol>
      <li>
        Cole and I have flipped (rehabbed) 3 houses and built 1. We started
        flipping when I was just 16 and he was 18. We did everything by
        ourselves, by hand, and were able to make profits on all of them! One of
        our biggest accomplishments. <br />
      </li>
      <li>
        We are major Marvel nerds. Like bad. We have watched every single Marvel
        movie and series multiple times and it's pretty much all we talk about.
        <br />
      </li>
      <li>
        I have my Associates Degree in General Business, I graduated college
        when I was 18.<br />
      </li>
      <li>
        We are major STL sports fans and will talk about the Blues winning the
        Stanley Cup in 2019 no matter how irrelevant it becomes. <br />
      </li>
      <li>
        Tessa girl is named after Tesla Motors. Cole wants a Tesla more than
        anything. LOL<br />
      </li>
      <li>
        Cole and I had a hamster when we were dating, her name was Bucket and we
        loved her so much!<br />
      </li>
      <li>
        I am a major planner. If there's something I can have ready that will
        make our lives easier during an apocalypse, I'm doing it. Backup food
        and water, weather radios, meeting place, anything. <br />
      </li>
      <li>Cole and I want to move to Texas.<br /></li>
      <li>
        I used to be a picky eater up until recently. I still won't eat some
        things, but the one thing I could never eat...onions.<br />
      </li>
      <li>
        I love the idea of flying in airplanes, but every time I do, I end up
        crying and having a panic attack the whole time. No joke, I am terrified
        once I'm in the air and it's uncontrollable.
      </li>
    </ol>
  </div>
  <div id="like-to-know-it"></div>
  <!-- <html> -->
  <div
    id="ltParentkwidget-version-two602656292"
    data-appid="602656292"
    class="ltkwidget-version-two"
  >
    <!-- <script type="application/javascript">
        var rsLTKLoadApp = "0",
          rsLTKPassedAppID = "602656292";
      </script> -->
    <!-- <script
        type="application/javascript"
        src="https://widgets-static.rewardstyle.com/widgets2_0/client/pub/ltkwidget/ltkwidget.js"
      ></script> -->
    <!-- <div
        widget-dashboard-settings=""
        data-appid="602656292"
        data-userid="314301"
        data-rows="3"
        data-cols="3"
        data-showframe="false"
        data-padding="4"
        data-profileid="e1732bb6-ae3e-11e9-86ca-4b28aa68f703"
      >
        <div class="rs-ltkwidget-container">
          <div ui-view=""></div>
        </div>
      </div>
      <noscript
        >This content requires JavaScript to be enabled, and the site or browser
        may be disabling it. Try reactivating it to view this content.</noscript
      > -->
  </div>
  <!-- </html> -->
</template>

<script>
export default {
  mounted() {
    let ltkParent = document.createElement("div");
    ltkParent.setAttribute("id", "ltParentkwidget-version-two602656292");
    ltkParent.setAttribute("DATA-APPID", "602656292");
    ltkParent.setAttribute("class", "ltkwidget-version-two");
    let ltkScriptOne = document.createElement("script");
    let ltkScriptScript = document.createTextNode(`var rsLTKLoadApp = "0",
          rsLTKPassedAppID = "602656292";`);
    ltkScriptOne.appendChild(ltkScriptScript);
    ltkParent.append(ltkScriptOne);
    console.log(ltkParent);
    let ltkScriptTwo = document.createElement("script");
    ltkScriptTwo.setAttribute("type", "application/javascript");
    ltkScriptTwo.setAttribute(
      "src",
      "https://widgets-static.rewardstyle.com/widgets2_0/client/pub/ltkwidget/ltkwidget.js"
    );
    ltkParent.append(ltkScriptTwo);
    let ltkChildDiv = document.createElement("div");
    ltkChildDiv.setAttribute("widget-dashboard-settings", "");
    ltkChildDiv.setAttribute("data-appid", "602656292");
    ltkChildDiv.setAttribute("data-userid", "314301");
    ltkChildDiv.setAttribute("data-rows", "3");
    ltkChildDiv.setAttribute("data-cols", "3");
    ltkChildDiv.setAttribute("data-showframe", "false");
    ltkChildDiv.setAttribute("data-padding", "4");
    ltkChildDiv.setAttribute(
      "data-profileid",
      "e1732bb6-ae3e-11e9-86ca-4b28aa68f703"
    );
    let ltkGrandChildDiv = document.createElement("div");
    ltkGrandChildDiv.setAttribute("class", "rs-ltkwidget-container");
    ltkChildDiv.append(ltkGrandChildDiv);
    let ltkGreatGrandChildDiv = document.createElement("div");
    ltkGreatGrandChildDiv.setAttribute("ui-view", "''");
    ltkGrandChildDiv.append(ltkGreatGrandChildDiv);
    let noscript = document.createElement("noscript");
    noscript.append(
      "This content requires JavaScript to be enabled, and the site or browser may be disabling it. Try reactivating it to view this content."
    );
    ltkParent.append(ltkChildDiv);
    console.log(ltkParent);
    // let masterdiv = document.querySelector("like-to-know-it");
    // console.log(masterdiv);
    document.head.append(ltkParent);
  },
};
</script>

<style scoped>
h1 {
  font-size: 60px;
}

div {
  margin: 0px 40px 10px 40px;
}
</style>