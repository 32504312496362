<template>
  <div id="wrapper">
    <loading-icon v-if="isLoading"></loading-icon>

    <div id="post-container" v-if="!isLoading">
      <blog-post-preview
        v-for="post in posts"
        :key="post.id"
        :title="post.title.rendered"
        :excerpt="post.excerpt.rendered"
        :featuredImage="post.jetpack_featured_media_url"
        :id="post.slug"
        :date="post.date"
      ></blog-post-preview>
    </div>
  </div>
</template>

<script>
import WPAPI from "wpapi";
import BlogPostPreview from "../components/BlogPostPreview.vue";
import LoadingIcon from "../components/LoadingIcon.vue";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});

export default {
  data() {
    return {
      isLoading: true,
      posts: [],
      categories: [],
    };
  },
  methods: {
    fetchPosts() {
      // try {
      // Fetch posts
      wp.posts()
        .embed()
        .tags()
        .get()
        .then((res) => {
          this.loadPosts(res);
        });

      // } catch (e) {
      // print error
      // console.log(e);
      // }
    },
    loadPosts(posts) {
      let array = [];
      posts.forEach((post) => {
        if (post.tags.includes(15)) {
          return;
        } else {
          array.push(post);
        }
      });
      this.posts = array;
      console.log(this.posts);
      this.isLoading = false;
    },
  },
  created() {
    // this.$nextTick(() => this.fetchPosts());
    this.fetchPosts();
  },
  components: { BlogPostPreview, LoadingIcon },
};
</script>

<style scoped>
/* @media (max-width: 767px) and (orientation: portrait) {
}
@media (min-width: 1280px) and (max-width: 1900px) {
}
#post-container {
  width: 100%;
}

@media only screen and (min-width: 992px) {
  #post-container {
    display: flex;
    flex-direction: column;
    margin-right: 75px;
  }

  .post {
    text-align: left;
  }

  .post:deep(li) {
    margin: 10px;
  }
} */
</style>