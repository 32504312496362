<template>
  <div id="wrapper">
    <div class="card" id="">
      <img src="../assets/biophoto.jpg" alt="" />
      <h1>Meet Lauryn</h1>
      <p><span>Hi! I'm Lauryn Coleman, and I'm so glad you're here.</span></p>
      <p>
        I'm a wife and a fur-baby mom living in St. Louis. I love to share my
        favorite things and all aspects of my life.
      </p>
    </div>
    <div class="card" id="subscribe">
      <h1>Get my updates!</h1>
      <div id="mc_embed_signup">
        <form
          action="https://laurynchayce.us1.list-manage.com/subscribe/post?u=7ce39bf009f4dea0b782470d4&amp;id=d06446513e"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll">
            <input
              type="email"
              value=""
              name="EMAIL"
              class="email"
              id="mce-EMAIL"
              placeholder="email address"
              required
            />
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div aria-hidden="true" id="hidden">
              <input
                type="text"
                name="b_7ce39bf009f4dea0b782470d4_d06446513e"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="card" id="removed-background">
    </div> -->
    <div class="card" id="instagram-follow">
      <h1>Follow Me On Instagram!</h1>
      <button>@laurynchayce</button>
      <img src="../assets/followoninsta.jpg" alt="" />
    </div>
    <div class="card" id="my-faves">
      <h1>My Faves</h1>
    </div>
  </div>
</template>

<script>
import WPAPI from "wpapi";

const wp = new WPAPI({
  endpoint: "https://chayceandco.com/wp-json",
});
export default {
  data() {
    return {
      post: null,
      loaded: true,
    };
  },
  methods: {
    getPost() {
      this.loaded = false;
      wp.posts()
        .slug("sidebar-widget")
        .get()
        .then((res) => {
          console.log(res);
          this.separateCodeAndPost(res[0]);
        });
    },
    separateCodeAndPost(res) {
      // separate code from content
      let content = res.content.rendered.split("<pre");
      // make code whole again
      let code = "<pre" + content[1].toString();

      // helper functions to clean unicode characters
      code = code.replaceAll("&amp;", "&");
      code = code.replaceAll("&lt;", "<");
      code = code.replaceAll("&#91;", "[");
      code = code.replaceAll("&gt;", ">");

      // creating the class attributes as variables

      let divClass = code.split("<div ");
      let firstClassindex = divClass[1].indexOf("class");
      let finalClassindex = divClass[1].indexOf("widget");
      divClass = divClass[1].slice(firstClassindex + 7, finalClassindex + 6);

      // creating the dynamic id as variables

      let divId = code.split("<div");
      divId = divId[1];
      let firstIdIndex = divId.indexOf("data-widget-id");
      let finalIdIndex = divId.indexOf(`">`);
      divId = divId.slice(firstIdIndex, finalIdIndex);
      console.log(divId);

      // cleaning `="`
      divId = divId.split('="');

      // create element and set attributes
      let div = document.createElement("div");
      div.setAttribute("class", divClass);
      div.setAttribute(divId[0], divId[1]);

      //create script content and clean
      let script = code.split("<script");
      script = script[1].split("/script>");
      script = script[0].split(">");
      script = script[1];
      script = script.substring(0, script.length - 1);
      console.log(script);
      //create script tag

      let htmlScript = document.createElement("script");
      htmlScript.text = script;
      htmlScript.type = "text/javascript";
      console.log(htmlScript);
      //eval and append script tag to div

      eval(htmlScript);
      div.append(htmlScript);
      console.log(div);
      let temp = document.getElementById("my-faves");

      temp.append(div);
    },
  },
  created() {
    this.getPost();
  },
};
</script>

<style scoped>
#mc_embed_signup_scroll {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#mc_embed_signup_scroll button {
}

#wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card {
  background-color: #fcf5f5;
  margin-bottom: 40px;
  padding: 10px;
}

.card img {
  width: 100%;
}

.card h1 {
  font-family: Nostalgic;
  font-size: 60px;
  margin: 5px;
  font-weight: 100;
}

.card p {
  font-family: "brandon-grotesque", sans-serif;
  color: #737373;
  font-weight: 400;

  font-size: 18px;
  padding: 10px;
}

.card button {
  font-family: "brandon-grotesque", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 300;

  justify-content: center;

  justify-items: center;
  text-align: center;
  background-color: #fafafa;
  border: 0.5px solid #737373;
  border: none;
  border-radius: 10px;
  padding: 8px 30px 8px 30px;
}

.card button:hover {
  background-color: white;
}

#subscribe {
  background-color: white;
  border: 3px solid #fcf5f5;
}

#subscribe button:hover {
  background-color: #f8f0f0;
}

#instagram-follow img {
  padding: 0;
  margin: 0;
}

#instagram-follow h1 {
  position: relative;
  top: +12px;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: capitalize;
  font-weight: 400;

  font-size: 20px;
}

#instagram-follow button {
  background-color: white;
  position: relative;
  font-size: 15px;
  bottom: -250px;
  margin: 0;
}
#instagram-follow button:hover {
  background-color: #fffbfb;
}

#my-faves {
  background-color: white;
  border: 3px solid #fcf5f5;
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .card {
    padding: 30px;
  }

  .card img {
    width: 80%;
  }

  .card h1 {
    font-size: 100px;
  }

  #instagram-follow button {
    bottom: -350px;
  }
}

@media only screen and (min-width: 768px) {
  #wrapper {
    width: 25%;
  }

  .card h1 {
    font-size: 50px;
  }

  #instagram-follow button {
    font-size: 12px;
    padding: 5px;
    bottom: -150px;
    margin: 0;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  #wrapper {
    width: 25%;
  }

  .card h1 {
    font-weight: 100;
  }

  #instagram-follow button {
    background-color: white;
    position: relative;
    font-size: 15px;
    bottom: -150px;
    margin: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  #wrapper {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  .card h1 {
    font-family: Nostalgic;
    font-weight: 100;
  }

  #instagram-follow button {
    background-color: white;
    position: relative;
    font-size: 15px;
    bottom: -150px;
    margin: 0;
  }
}

@media only screen and (min-width: 1200px) {
  #wrapper {
    width: 25%;
    display: flex;
    flex-direction: column;
  }

  .card h1 {
    font-family: Nostalgic;
    font-weight: 100;
    font-size: 75px;
  }

  #instagram-follow button {
    background-color: white;
    position: relative;
    font-size: 15px;
    bottom: -250px;
    margin: 0;
  }
}

@media (min-width: 1280px) and (max-width: 1900px) {
  #wrapper {
    width: 25%;
    margin: 0 0 0 30px;
    display: flex;
    flex-direction: column;
  }

  .card h1 {
    font-family: Nostalgic;
    font-weight: 100;
  }

  #instagram-follow button {
    background-color: white;
    position: relative;
    font-size: 15px;
    top: 50%;
    margin: 0;
  }
}
</style>