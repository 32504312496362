<template>
  <constant-half-height-header></constant-half-height-header>
  <div id="body-wrapper">
    <div id="router-view">
      <router-view />
    </div>
    <constant-side-bar></constant-side-bar>
  </div>
  <the-footer />
  <div id="temp"></div>
</template>



<script>
import ConstantHalfHeightHeader from "./components/ConstantHalfHeightHeader.vue";
import ConstantSideBar from "./components/ConstantSideBar.vue";
import TheFooter from "./components/Footer.vue";

export default {
  data() {
    return {
      // likeToKnowIt: undefined,
    };
  },
  methods: {},
  components: {
    ConstantHalfHeightHeader,
    ConstantSideBar,
    TheFooter,
  },
  mounted() {},
};
</script>
<style>
@import url("https://use.typekit.net/ita2cba.css");

@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "nostalgic";
  src: url(./fonts/Nostalgic/Nostalgic.otf) format("opentype"),
    url(./fonts/Nostalgic/Nostalgic.ttf) format("truetype"),
    url(./fonts/Nostalgic/Nostalgic.woff) format("woff"),
    url(./fonts/Nostalgic/Nostalgic.woff2) format("woff2");
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  position: fixed;
  color: white;
}
#app {
  width: 100vw;
  height: 100vh;
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #737373;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
}

h1 {
  font-family: "nostalgic";
  font-weight: 200;
  color: #737373;
}

a {
  text-decoration: none;
  color: #737373;
}

div {
  margin: 0;
}

#router-view {
  width: 100%;
}

#body-wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 50px 5% 0 5%;
}

#body:deep(p),
#body:deep(li) {
  font-family: "brandon-grotesque", sans-serif;
  font-weight: 600;
}

#body:deep(a) {
  color: #737373;
  background-color: #fcf5f5;
}

@media (min-width: 320px) and (max-width: 767px) {
  #body-wrapper {
    flex-direction: column;
    width: 90%;
    margin: 50px 5% 0 5%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  #body-wrapper {
    flex-direction: row;
    max-width: 80%;
    margin: 10px 10% 0 10%;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  #body-wrapper {
    flex-direction: row;
    max-width: 80%;
    margin: 10px 10% 0 10%;
  }
}

@media (min-width: 1200px) {
  #body-wrapper {
    flex-direction: row;
    max-width: 80%;
    margin: 10px 10% 0 10%;
  }
}
</style>

