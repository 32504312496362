<template>
  <div id="three-photo-div">
    <div class="third">
      <img src="../assets/left-div.jpg" alt="" />
      <button>
        <a href="https://liketoknow.it/laurynchayce" target="_blank">
          Shop My Insta</a
        >
      </button>
    </div>
    <div class="third">
      <img src="../assets/middle-div.jpg" alt="" /><router-link to="todayssales"
        ><button>Today's Sales</button></router-link
      >
    </div>
    <div class="third">
      <img src="../assets/right-div.jpg" alt="" /><button>
        <a href="https://amazon.com/shop/influencer-25f538a9" target="_blank">
          Shop my Amazon</a
        >
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* #three-photo-div {
  padding-top: 80px;
  display: flex;
  width: 100%;
  margin: 0 15% 0 15%;
  justify-content: center;
  justify-items: center;
}

.third {
  flex: 1;
  height: 400px;
  width: calc(100% / 4);
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.third button {
  position: relative;
  top: -30px;
  background-color: #fafafa;
  font-family: Brandon Grotesque, helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 16px;
  color: #737373;
  border: none;
  width: 60%;
  padding: 20px 0 20px 0;
  align-self: center;
  justify-self: center;
}

.third button:hover {
  cursor: pointer;
  background-color: #fffbfb;
}

.third button:focus {
  outline: none;
}

.third img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
@media only screen and (max-width: 479px) {
  #three-photo-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  #three-photo-div {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 5% 0 5%;
    /* justify-content: center;
    justify-items: center; */
  }

  .third {
    flex: 1;
    height: 400px;
    /* width: calc(100% / 4); */
    display: flex;
    flex-direction: column;
    /* padding: 20px; */
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 768px) and (max-width: 979px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 16px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1023px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    color: #737373;
    border: none;
    width: 60%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 0 5% 0 5%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    color: #737373;
    border: none;
    min-width: 70%;
    padding: 20px 0 20px 0;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1200px) {
  #three-photo-div {
    padding-top: 80px;
    display: flex;
    flex-direction: row;
    width: 70%;
    margin: 0 15% 0 15%;
    justify-content: center;
    justify-items: center;
  }

  .third {
    flex: 1;
    height: 100%;
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .third button {
    position: relative;
    top: -30px;
    background-color: #fafafa;
    font-family: "brandon-grotesque", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 400;

    font-size: 16px;
    color: #737373;
    border: none;
    min-width: 70%;
    padding: 20px 20px 20px 20px;
    align-self: center;
    justify-self: center;
  }

  .third button:hover {
    cursor: pointer;
    background-color: #fffbfb;
  }

  .third button:focus {
    outline: none;
  }

  .third img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>